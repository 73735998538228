<template>
    <div>
        <Col class="text-right">
            <Button class="flex-right" @click="$router.go(-1)"> Go back </Button>
        </Col>

        <Form ref="assetForm" :model="formData" :rules="ruleInline" label-position="left">
            <Row>
                <Col span="10">
                    <Upload
                        v-if="!formData.mintAddress"
                        class="uploadAsset"
                        prop="image"
                        type="drag"
                        :multiple="false"
                        action=""
                        :show-upload-list="true"
                        :before-upload="handleOnUpload"
                    >
                        <div class="upload-info">
                            <img :src="formData.image || formData.file" width="100%" />
                            <Icon type="ios-cloud-upload" color="#3399ff" size="50"></Icon>
                            <p>
                                {{ formData._id ? 'Replace Image' : 'Click or drag a digital asset here to upload' }}
                            </p>
                        </div>
                    </Upload>

                    <img v-else :src="formData.file" width="100%" />
                </Col>

                <Col span="12" class="addAssetForm">
                    <h2 v-if="!formData._id" class="md-margin-bottom">ADD NEW ASSET</h2>
                    <div v-else class="md-margin-bottom">
                        <h2>
                            <b>
                                {{ isAdmin ? 'ADMIN' : 'EMPLOYEE' }} NFTS -
                                {{ categoryName(formData.category_name) }}
                            </b>
                        </h2>

                        <div v-if="formData.mintAddress">
                            <p>
                                Owned by:
                                {{ ownedBy }}
                            </p>
                        </div>
                    </div>
                    <Row>
                        <Col span="20">
                            <FormItem label="Title" prop="name">
                                <Input v-model="formData.name" :disabled="formData.mintAddress" />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <Col span="20">
                            <FormItem label="Description" prop="description">
                                <Input
                                    v-model="formData.description"
                                    type="textarea"
                                    :disabled="formData.mintAddress"
                                />
                            </FormItem>
                        </Col>
                    </Row>

                    <Row>
                        <FormItem label="Category" prop="category">
                            <RadioGroup v-model="formData.category" class="createCat">
                                <Radio
                                    v-for="category in categories"
                                    :key="category._id"
                                    :label="category._id"
                                    :disabled="formData.mintAddress"
                                >
                                    {{ categoryName(category.name) }}
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                    </Row>

                    <Row>
                        <Col span="20">
                            <Button
                                v-if="!formData._id"
                                class="pt-btn-4"
                                :loading="isProcessing"
                                long
                                @click="handleSubmitForm"
                            >
                                <strong>CREATE ASSET</strong>
                            </Button>
                            <div v-else>
                                <p class="md-margin-bottom" :class="formData.mintAddress ? 'greenStatus' : 'redStatus'">
                                    <strong>Status: {{ formData.mintAddress ? 'Minted' : 'Not Minted' }}</strong>
                                </p>
                                <Row class="md-margin-bottom margin-btns">
                                    <Col span="11">
                                        <Button
                                            :disabled="formData.mintAddress || (isProcessing && loaderFor !== 'mint')"
                                            class="pt-btn-4"
                                            :loading="loaderFor === 'mint'"
                                            long
                                            @click="handleMintAsset"
                                        >
                                            <strong>Mint NFT</strong>
                                        </Button>
                                    </Col>
                                    <Col span="11">
                                        <Button
                                            :disabled="formData.mintAddress || (isProcessing && loaderFor !== 'delete')"
                                            class="pt-btn-1"
                                            :loading="loaderFor === 'delete'"
                                            long
                                            @click="handleDeleteAsset"
                                        >
                                            <strong>Delete Asset</strong>
                                        </Button>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col v-if="formData.mintAddress" span="24">
                                        <Button
                                            v-if="!formData.isTransferred"
                                            class="pt-btn-4 updateAssetBtn"
                                            :loading="isProcessing"
                                            :disabled="formData.isTransferred"
                                            long
                                            @click="$emit('handleSelectEmployee')"
                                        >
                                            <strong>TRANSFER NFT</strong></Button
                                        >

                                        <Button
                                            v-else
                                            class="pt-btn-4 updateAssetBtn"
                                            :loading="isProcessing"
                                            :disabled="
                                                formData.transactionLogs.find(
                                                    (log) => log.transaction.type === 'transferred'
                                                ).transaction.congratulatoryEmail
                                            "
                                            long
                                            @click="handleShowEmailDialog"
                                        >
                                            <strong>SEND EMAIL</strong></Button
                                        >
                                    </Col>
                                </Row>

                                <p v-if="formData.isTransferred" class="sm-margin-top redStatus">
                                    Cannot transfer if you are not the owner of the NFT
                                </p>

                                <div v-else>
                                    <Row>
                                        <Col span="24">
                                            <Button
                                                v-if="!formData.mintAddress"
                                                class="pt-btn-3 updateAssetBtn"
                                                :loading="loaderFor === 'update'"
                                                :disabled="isProcessing && loaderFor !== 'update'"
                                                @click="handleSubmitForm"
                                            >
                                                <strong>UPDATE ASSET</strong>
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <SendEmail
                v-if="showEmailModal"
                :email="formData.user.email"
                :transaction-id="
                    formData.transactionLogs.find((log) => log.transaction.type === 'transferred').transaction._id
                "
                @handleEmailSent="$emit('handleEmailSent')"
            />
        </Form>
    </div>
</template>

<script>
import { pick, startCase } from 'lodash';
import { createAsset, getCategories, updateAsset } from '@/services/nft.services';
import SendEmail from './SendEmail';

export default {
    components: {
        SendEmail,
    },
    props: {
        asset: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showEmailModal: false,
            isProcessing: false,
            loaderFor: '',
            isUpdate: false,
            categories: [],
            formData: {
                _id: null,
                name: '',
                description: '',
                category: '',
                image: '',
                isNewImage: false,
                mintAddress: null,
                fileName: '',
                user: {},
            },
            ruleInline: {
                name: [
                    {
                        required: this.asset?.mintAddress ? false : true,
                        message: 'Please fill in the title',
                        trigger: 'blur',
                    },
                ],
                description: [
                    {
                        required: this.asset?.mintAddress ? false : true,
                        message: 'Please fill in the description',
                        trigger: 'blur',
                    },
                ],
                category: [
                    {
                        required: this.asset?.mintAddress ? false : true,
                        message: 'Please pick a category',
                        trigger: 'blur',
                    },
                ],
                image: [
                    {
                        required: this.asset?.mintAddress ? false : true,
                        message: 'Please upload a file',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    computed: {
        categoryName: () => (name) => {
            return startCase(name);
        },
        isAdmin: {
            get() {
                return !this.formData.user?._id;
            },
        },
        ownedBy: {
            get() {
                const mintDetail = this.formData.mintDetail;
                const blockchainWalletAddress = this.isAdmin
                    ? mintDetail.creators[0].address
                    : this.formData.user.blockchainWalletAddress;
                return this.isAdmin
                    ? `Startechup - ${blockchainWalletAddress}`
                    : `${this.formData.user?.name} - ${blockchainWalletAddress}`;
            },
        },
    },
    async created() {
        const { data } = await getCategories();
        this.categories = data;

        if (!this.formData.category) {
            this.formData.category = this.categories[0]?._id;
        }
    },
    async mounted() {
        if (this.asset?._id) {
            const asset = this.asset;

            this.formData = {
                ...asset,
                category: asset.category._id,
                category_name: asset.category.name,
            };

            this.isUpdate = true;
        }
    },
    methods: {
        handleGoToCollections() {
            this.$router.push({ name: 'NFT' });
        },
        handleMintAsset() {
            this.isProcessing = true;
            this.loaderFor = 'mint';
            this.$emit('handleMintAsset');
        },
        handleDeleteAsset() {
            this.isProcessing = true;
            this.loaderFor = 'delete';
            this.$emit('handleDeleteAsset');
        },
        async handleOnUpload(file) {
            const image = await new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);

                fileReader.onload = () => {
                    resolve(fileReader.result);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            });

            this.formData = {
                ...this.formData,
                image,
                fileName: file.name,
            };
            if (this.isUpdate) {
                this.isNewImage = true;
            }
        },
        async handleSubmitForm() {
            if (!(await this.$refs.assetForm.validate((valid) => valid))) {
                this.$Message.error('Fill in the required fields');
                return;
            }

            if (!this.isUpdate && !this.formData.image) {
                this.$Message.error('Please upload image first');
                return;
            }

            this.isProcessing = true;
            let payload = {
                ...pick(this.formData, 'name', 'description', 'category', 'image', 'fileName'),
            };

            if (this.isUpdate) {
                payload = {
                    ...payload,
                    isNewImage: this.isNewImage,
                };
            }

            const action = this.isUpdate ? 'update' : 'create';
            this.loaderFor = action;
            const methodToInvoke = this.isUpdate ? updateAsset(this.formData._id, payload) : createAsset(payload);

            methodToInvoke
                .then(({ data }) => {
                    this.isProcessing = false;
                    this.loaderFor = '';
                    this.isNewImage = false;
                    this.$Message.success(`Asset successfully ${action}!`);

                    if (!this.isUpdate) {
                        this.$router.push({ name: 'NFTUpdate', params: { id: data.asset._id } });
                    }
                })
                .catch(() => {
                    this.isProcessing = false;
                    this.$Message.error(`Failed to ${action} asset!`);
                });
        },
        handleShowEmailDialog() {
            this.showEmailModal = !this.showEmailModal;
        },
    },
};
</script>
