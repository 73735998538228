<template>
    <div>
        <Spin v-if="isLoading" size="large" fix />
        <div>
            <CreateAsset
                :key="createComponentKey"
                :asset="asset"
                @handleDeleteAsset="handleDeleteAsset"
                @handleMintAsset="handleMintAsset"
                @handleSelectEmployee="handleSelectEmployee"
                @handleEmailSent="handleDoneTransfer"
            />
        </div>

        <Modal v-model="showEmployeeSelection" title="Transfer NFT" footer-hide>
            <TransferNft
                @handleSelectEmployee="handleSelectEmployee"
                @handleDoneTransfer="handleDoneTransfer"
                @handleEmailSent="handleDoneTransfer"
            />
        </Modal>

        <Card v-if="asset.transactionLogs.length" class="lg-margin-top">
            <h3 class="md-margin-bottom">TRANSACTION LOGS</h3>

            <Table
                highlight-row
                height="250"
                size="small"
                class="client-projects-tbl"
                :columns="tableColumns"
                :data="asset.transactionLogs"
                :loading="isLoading"
            />
        </Card>

        <Modal v-model="logToView.show" footer-hide width="800">
            <ViewTransactionLog v-if="logToView.show" :transaction="logToView" />
        </Modal>
    </div>
</template>

<script>
import { getAsset, deleteAsset, mintAsset } from '@/services/nft.services';
import CreateAsset from './CreateAsset';
import TransferNft from './TransferNft';
import ViewTransactionLog from './ViewTransactionLog';
import { startCase, pick } from 'lodash';
import moment from 'moment';

export default {
    components: {
        CreateAsset,
        TransferNft,
        ViewTransactionLog,
    },
    data() {
        return {
            isLoading: false,
            isDeleting: false,
            asset: {
                transactionLogs: [],
            },
            showEmployeeSelection: false,
            logToView: {
                show: false,
            },
            tableColumns: [
                {
                    title: 'Title',
                    key: 'type',
                    render: (h, params) => h('div', startCase(params.row.transaction.type)),
                    width: 180,
                },
                {
                    title: 'Wallet',
                    render: (h, params) => h('div', this.structureWallet(params.row).wallet),
                },
                {
                    title: 'Date',
                    key: 'createdAt',
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.transaction.createdAt
                                ? moment(params.row.transaction.createdAt).format('DD-MMM-YYYY')
                                : ''
                        );
                    },
                },
                {
                    title: 'Actions',
                    key: 'id',
                    width: 150,
                    fixed: 'right',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'md-eye',
                                    size: '20',
                                },
                                on: {
                                    click: () => {
                                        const { transaction } = params.row;
                                        const walletStructure = this.structureWallet(params.row);

                                        this.logToView = {
                                            show: true,
                                            ...transaction,
                                            type: transaction.type.toUpperCase(),
                                            createdAt: moment(transaction.createdAt).format('MMM DD, YYYY'),
                                            creatorWallet: walletStructure.creatorWallet,
                                            destinationWallet: walletStructure.wallet,
                                        };
                                    },
                                },
                            }),
                        ]);
                    },
                },
            ],
        };
    },
    computed: {
        createComponentKey: {
            get() {
                return `${this.asset._id}-${this.asset?.user?._id}`;
            },
        },
    },
    async created() {
        this.setAssetData();
    },
    methods: {
        async setAssetData() {
            this.isLoading = true;
            const assetId = this.$route.params.id;
            const { data } = await getAsset(assetId);
            this.asset = {
                ...data.asset,
                file: data.file,
                ...pick(data, 'transactionLogs', 'mintDetail'),
            };
            this.isLoading = false;
        },
        handleGoToCollections() {
            this.$router.push({ name: 'NFT' });
        },
        async handleDeleteAsset() {
            if (this.asset.mintAddress) {
                return;
            }

            this.isDeleting = true;
            deleteAsset(this.asset._id)
                .then(() => {
                    this.$router.push({ name: 'NFT' });
                    this.$Message.success('Asset successfully deleted!');
                })
                .catch(() => {
                    this.isDeleting = false;
                    this.$Message.error('Failed to delete asset!');
                });
        },
        async handleMintAsset() {
            if (this.asset.mintAddress) {
                return;
            }

            this.isProcessing = true;
            mintAsset(this.asset._id)
                .then(() => {
                    this.asset._id = null;
                    this.$Message.success('Asset successfully minted!');
                    this.setAssetData();
                })
                .catch(() => {
                    this.isProcessing = false;
                    this.$Message.error('Failed to mint asset!');
                });
        },
        handleSelectEmployee() {
            this.showEmployeeSelection = !this.showEmployeeSelection;
        },
        handleDoneTransfer() {
            this.setAssetData();
        },
        structureWallet(data) {
            const { transaction, mintDetail } = data;

            const user = transaction.asset.user;
            const isMintedTransaction = transaction.type === 'minted';
            const creatorWallet = mintDetail?.creators?.[0].address;
            const blockchainWalletAddress = isMintedTransaction ? creatorWallet : user.blockchainWalletAddress;

            const wallet = isMintedTransaction
                ? `Startechup - ${blockchainWalletAddress}`
                : `${user?.name} - ${blockchainWalletAddress}`;

            return {
                wallet,
                creatorWallet,
            };
        },
    },
};
</script>
