<template>
    <div>
        <Modal
            v-model="showEmailModal"
            footer-hide
            :mask-closable="false"
            class="weekly-email-composer"
            style="position: relative"
            width="1000"
        >
            <h2 class="md-margin-bottom"><strong>SEND A CONGRATULATORY EMAIL</strong></h2>

            <Form :model="emailFormData">
                <div class="sm-margin-bottom">
                    <strong>To: {{ email }}</strong>
                </div>
                <FormItem label="Subject" prop="subject" class="md-margin-bottom">
                    <Input v-model="emailFormData.subject" type="text" size="large" />
                </FormItem>

                <TinyMceEditor @messages="(val) => (emailFormData.content = val)" />

                <Button
                    class="pt-btn-1 text-center md-margin-top btn-congrats"
                    :loading="isProcessing"
                    @click="handleSendEmail"
                >
                    Send
                </Button>
            </Form>
        </Modal>
    </div>
</template>

<script>
import { sendEmailToNewOwner } from '@/services/nft.services';
import TinyMceEditor from '@/components/UI/TinyMceEditor';

export default {
    components: {
        TinyMceEditor,
    },
    props: {
        email: {
            type: String,
            default: () => {},
        },
        transactionId: {
            type: String,
            default: () => {},
        },
    },
    data() {
        return {
            isProcessing: false,
            showEmailModal: true,
            emailFormData: {
                subject: '',
                content: '',
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        async handleSendEmail() {
            if (!this.emailFormData.content) {
                this.$Message.error('Please fill in the content of the email first');
                return;
            }

            this.isProcessing = true;
            sendEmailToNewOwner({
                transactionId: this.transactionId,
                to: this.email,
                ...this.emailFormData,
            })
                .then(() => {
                    this.$Message.success('Congratulatory email sent successfully');
                    this.$emit('handleEmailSent');
                    this.isProcessing = false;
                    this.showEmailModal = false;
                })
                .catch(({ data }) => {
                    this.$Message.error(data.message);
                    this.isProcessing = false;
                });
        },
    },
};
</script>
