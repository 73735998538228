<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Edit Leaves"
            width="600"
            no-footer
            :closable="closable"
            class="updateRequest"
            :before-close="handleReset"
        >
            <Form ref="form" :model="form" label-position="left" class="update-time-dispute-wrapper">
                <Row>
                    <Col span="12">
                        <FormItem label="Date submitted" prop="createdAt">
                            <DatePicker v-model="form.createdAt" type="date" transfer disabled />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col span="8">
                        <FormItem label="Requested Start Date" prop="requestedStart">
                            <DatePicker
                                v-model="form.requestedStart"
                                type="date"
                                placeholder="Select request start date"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Requested End Date" prop="requestedEnd">
                            <DatePicker
                                v-model="form.requestedEnd"
                                type="date"
                                placeholder="Select request end date"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="">
                        <FormItem label="Leave Type" prop="leaveType">
                            <v-radio-group v-model="form.leaveType">
                                {{ leaves.vl }}<v-radio label="Vacation Leave" value="vl"> </v-radio> {{ leaves.sl
                                }}<v-radio label="Emergency/Sick Leave" value="sl"> {{ leaves.sl }}</v-radio>
                                {{ leaves.al }}
                                <v-radio label="Appreciation Leave" value="al"> {{ leaves.al }}</v-radio>
                            </v-radio-group>
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="form.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleUpdateLeave"> SUBMIT </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { changeLeave } from '../../../../services/requests.services';
import { getLeavesInfo } from '../../../../services/leave.services';
import moment from 'moment';

export default {
    data() {
        return {
            attendanceLogData: null,
            requestData: null,
            isLoading: false,
            closable: false,
            form: {
                id: null,
                createdAt: null,
                requestedStart: null,
                requestedEnd: null,
                leaveType: '',
                reason: '',
            },
            leaves: {
                vl: 0,
                sl: 0,
                al: 0,
            },
            ruleInline: {
                requestedStart: [
                    {
                        required: true,
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                requestedEnd: [
                    {
                        required: true,
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    methods: {
        async handleUpdateLeave() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.updateLeave();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        updateLeave() {
            this.isLoading = true;

            const data = {
                requestedStart: moment(this.form.requestedStart).format('yyyy-MM-DD'),
                requestedEnd: moment(this.form.requestedEnd).format('yyyy-MM-DD'),
                type: this.form.leaveType,
                reason: this.form.reason ? this.form.reason : '',
            };
            changeLeave({ id: this.form.id, data })
                .then(() => {
                    this.$Message.success('Request time dispute successfully updated');
                    this.isLoading = false;
                    this.$emit('create');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Request start time value should not be ahead/or equal to the end time'
                    ) {
                        this.$Message.error('Request start time value should not be ahead/or equal to the end time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message ===
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                    ) {
                        this.$Message.error(
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                        );
                    } else {
                        this.$Message.error('Failed to add request time dispute');
                    }
                    this.isLoading = false;
                });
        },
        async populateLeavesInfo() {
            const { data } = await getLeavesInfo();
            this.leaves.sl = data['remaining-sick-leave'];
            this.leaves.al = data['remaining-appreciation-leave'];
            this.leaves.vl = data['remaining-vacation-leave'];
        },
        async openModal(data, fromShiftTable) {
            console.log('openModalData: ', data);
            await this.populateLeavesInfo();
            if (fromShiftTable) {
                this.form = {
                    id: data._id,
                    createdAt: data.createdAt,
                    requestedStart: data.requestedStart,
                    requestedEnd: data.requestedStart,
                    reason: data.reason,
                    leaveType: data.requestedData.type,
                };
            } else {
                this.requestData = data;
                this.form = {
                    id: data._id,
                    createdAt: data.createdAt,
                    requestedStart: data.requestedStart,
                    requestedEnd: data.requestedStart,
                    reason: data.reason,
                    leaveType: data.requestedData.type,
                };
            }
            console.log('update info: ', this.form);
            this.isLoading = false;
            this.showModal = true;
        },

        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.updateRequest .ivu-modal-body {
    padding: 16px;
}
.updateRequest .ivu-modal-footer {
    display: none;
}
.updateRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.update-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    .update-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
