<template>
    <div>
        <Spin v-if="isLoading" size="large" fix />
        <div class="md-margin-bottom">
            <Input
                v-model="assetName"
                class="md-margin-right"
                suffix="ios-search"
                placeholder="Search NFT"
                style="width: 300px"
                @on-change="handleSetAssets"
            />
            <Button class="pt-btn-1" @click="handleGoToCreateAsset"> <strong>Upload Asset</strong> </Button>
        </div>

        <div v-if="assets && Object.keys(assets).length">
            <div v-for="name in Object.keys(assets)" :key="name" class="">
                <h3 class="md-margin-bottom">
                    <strong>{{ categoryName(name) }}</strong>
                </h3>
                <Row :gutter="16">
                    <Col v-for="data in assets[name]" :key="data.id" :gutter="16" span="6" class="md-margin-bottom">
                        <Card class="asset-no-padding">
                            <div
                                class="card-img"
                                :style="background(data.file)"
                                @click="handleViewAsset(data.asset)"
                            ></div>
                            <div class="info-padding">
                                <p>
                                    <strong>{{ data.asset.name }}</strong>
                                </p>
                                <p>
                                    Owner: {{ data.asset.isTransferred ? data.asset.user.name : 'Startechup' }} |
                                    {{ categoryName(data.asset.category.name) }}
                                </p>
                                <p :class="data.asset.mintAddress ? 'greenStatus' : 'redStatus'">
                                    Status:
                                    {{
                                        data.asset.isTransferred
                                            ? 'Transferred'
                                            : data.asset.mintAddress
                                            ? 'Minted'
                                            : 'Not Minted'
                                    }}
                                </p>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>

            <Page
                v-if="count"
                :current="currentPage"
                :total="count"
                :page-size="8"
                size="small"
                class="nftPagination pagination md-margin-top"
                @on-change="handleOnPageChange"
            />
        </div>
        <p v-else class="text-center">No Data</p>
    </div>
</template>

<script>
import { getAssets } from '@/services/nft.services';
import { startCase } from 'lodash';

export default {
    components: {},
    props: {
        filters: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            assetName: null,
            currentPage: 1,
            count: 0,
            assets: {},
            isLoading: false,
            url: 'https://file.iviewui.com/images/image-demo-11.jpg',
        };
    },
    computed: {
        categoryName: () => (name) => {
            return startCase(name);
        },
        background: () => (file) => `background-image: url(${file})`,
    },
    created() {
        this.handleSetAssets();
    },
    methods: {
        async handleSetAssets() {
            this.isLoading = true;
            const { data } = await getAssets({ ...this.filters, page: this.currentPage, assetName: this.assetName });
            this.assets = data.data;
            this.count = data.totalCount;
            this.isLoading = false;
        },
        handleGoToCreateAsset() {
            this.$router.push({ name: 'NFTCreate' });
        },
        handleViewAsset(data) {
            this.$router.push({ name: 'NFTUpdate', params: { id: data._id } });
        },
        handleOnPageChange(page) {
            this.currentPage = page;
            this.handleSetAssets();
        },
    },
};
</script>
