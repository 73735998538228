<template>
    <div>
        <ViewAttendance ref="ViewAttendance" @reload="handleReloadList" />
        <div>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="attendanceList" />
        </div>
        <Page
            :total="totalCount"
            :current="currentPage"
            :page-size="25"
            class="pagination"
            @on-change="handleOnpageChanged"
        />
    </div>
</template>

<script>
import moment from 'moment';
import ViewAttendance from '@/components/Attendance/Dev/ViewAttendance';
export default {
    components: { ViewAttendance },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        attendanceLogsList: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        totalPerPage: {
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            tblColumns: [
                {
                    title: 'Date',
                    key: 'createdAt',
                    width: 200,
                    render: (h, params) => {
                        return h(
                            'div',
                            params.row.createdAt ? moment(params.row.createdAt).utc().format('YYYY-MM-DD') : ''
                        );
                    },
                },
                {
                    title: 'First Clock In',
                    key: 'clockInTime',
                    //fixed: 'left',
                    width: 200,
                    render: (h, params) => {
                        // const date = params.row.status.includes('leave') ? params.row.clockInTime : params.row.createdAt
                        return !['leave|vl', 'leave|sl', 'leave|al', 'absent', 'rest'].includes(params.row.status)
                            ? h('div', moment(params.row.clockInTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');

                        // return params.row.clockInTime
                        //     ? h('div', moment(params.row.clockInTime).utc().format('hh:mm A'))
                        //     : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Last Clock Out',
                    key: 'clockOutTime',
                    width: 200,
                    render: (h, params) => {
                        return !['leave|vl', 'leave|sl', 'leave|al', 'absent', 'rest'].includes(params.row.status)
                            ? h('div', moment(params.row.clockOutTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');

                        // return params.row.clockOutTime
                        //     ? h('div', moment(params.row.clockOutTime).utc().format('hh:mm A'))
                        //     : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalDailyHrs',
                    width: 200,
                    render: (h, params) => {
                        return params.row.totalDailyHrs
                            ? h('div', Number(params.row.totalDailyHrs).toFixed(0) + ' hours')
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Status',
                    key: 'status',
                    minWidth: 250,
                    render: (h, params) => {
                        // return h('div', params.row.status);
                        const status = params.row.status;
                        let label = '',
                            className = 'statusLabel';
                        const upperCaseStats = status.toString().toUpperCase();
                        switch (status.toString().trim()) {
                            case 'regular':
                                label = upperCaseStats;
                                className = `${className} success`;
                                break;
                            case 'snonworkholiday':
                                label = 'SPECIAL NON-WORKING HOLIDAY';
                                className = `${className} success`;
                                break;
                            case 'sworkholiday':
                                label = 'SPECIAL WORKING HOLIDAY';
                                className = `${className} success`;
                                break;
                            case 'regholiday':
                                label = 'REGULAR HOLIDAY';
                                className = `${className} secondary`;
                                break;
                            case 'rest':
                                label = 'REST DAY';
                                className = `${className} secondary`;
                                break;
                            case 'absent':
                                label = upperCaseStats;
                                className = `${className} danger`;
                                break;
                            case 'late':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'leave':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|vl':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|sl':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'leave|al':
                                label = `ON ${upperCaseStats}`;
                                className = `${className} primary`;
                                break;
                            case 'undertime':
                                label = upperCaseStats;
                                className = `${className} warning`;
                                break;
                            case 'timedispute':
                                label = 'TIME DISPUTE';
                                className = `${className} warning`;
                                break;
                            default:
                                break;
                        }
                        return h(
                            'div',
                            {
                                class: `${className}`,
                            },
                            label
                        );
                    },
                },
                {
                    title: 'Actions',
                    width: 200,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    { class: 'pt-btn-3' },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    this.$refs.ViewAttendance.openModal(params.row);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        attendanceLogsList(data) {
            this.tblData = data;
        },
        totalPerPage(total) {
            const arrTotal = [];
            arrTotal.push(total);
        },
    },
    methods: {
        handleReloadList() {
            this.$emit('reload');
        },
        handleOnpageChanged(page) {
            this.$emit('on-page-change', page);
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.attendanceList button.pt-btn-3 {
    width: 25px;
}
.attendanceList i.ivu-icon {
    margin-left: -12.5px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
}
.primary {
    background: #211841;
}
.secondary {
    background: #828899;
}
</style>
