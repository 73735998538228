<template>
    <div>
        <div class="d-flex-center lg-margin-bottom">
            <p class="md-margin-right">Project</p>
            <Select v-model="selectedUserId" filterable clearable :loading="isLoading" class="md-margin-right">
                <Option v-for="user in users" :key="user._id" :value="user._id">
                    {{ user.name }}
                </Option>
            </Select>
        </div>

        <Col>
            <Button
                class="pt-btn-3 text-center transfer-btn"
                :loading="isLoading"
                :disabled="!selectedUserId"
                @click="handleTransferConfirmation"
            >
                TRANSFER
            </Button>
        </Col>

        <Modal v-model="showTransferModal" title="Transfer NFT" footer-hide :closable="false" :mask-closable="false">
            <div>
                <p class="text-center">
                    You are about to transfer your nft to wallet <b> {{ selectedUser.blockchainWalletAddress }}</b>
                    <br /><br /><span class="sm-margin-top">Continue?</span>
                </p>

                <div class="savePTBtn">
                    <Button
                        class="pt-btn-3 transferNow-btn"
                        :loading="isLoading"
                        :disabled="!selectedUserId"
                        @click="handleTransferNft"
                    >
                        TRANSFER NOW
                    </Button>
                </div>
            </div>
        </Modal>

        <SendEmail
            v-if="showEmailModal"
            :email="selectedUser.email"
            :transaction-id="transactionId"
            @handleEmailSent="$emit('handleEmailSent')"
        />
    </div>
</template>

<script>
import { getUsers } from '@/services/users.services';
import { transferNft } from '@/services/nft.services';
import SendEmail from './SendEmail';

export default {
    components: {
        SendEmail,
    },
    data() {
        return {
            selectedUserId: '',
            isLoading: false,
            users: [],
            showTransferModal: false,
            showEmailModal: false,
            transactionId: null,
        };
    },
    computed: {
        selectedUser: {
            get() {
                return this.users.find((user) => user._id === this.selectedUserId) || {};
            },
        },
    },
    mounted() {
        getUsers()
            .then((res) => {
                this.users = res.data.filter((user) => user.blockchainWalletAddress);
            })
            .catch(() => {
                this.$Message.error('Failed to get list of users with linked wallets!');
            });
    },
    methods: {
        handleTransferConfirmation() {
            this.$emit('handleSelectEmployee');
            this.showTransferModal = true;
        },
        handleTransferNft() {
            this.isLoading = true;
            transferNft(this.$route.params.id, { receiverId: this.selectedUserId })
                .then(({ data }) => {
                    this.$emit('handleDoneTransfer');
                    this.$Message.success(`NFT successfully sent to new owner ${this.selectedUser.name}`);
                    this.showTransferModal = false;
                    this.isLoading = false;
                    this.showEmailModal = true;
                    this.transactionId = data.transaction._id;
                })
                .catch(({ data }) => {
                    // re-try until the process succeed
                    if (data.statusCode === 500) {
                        this.handleTransferNft();
                    } else {
                        this.$Message.error(data.message);
                        this.isLoading = false;
                    }
                });
        },
    },
};
</script>
