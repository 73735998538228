<template>
    <div>
        <h1>{{ transaction.type }}</h1>
        <h5 class="tdate md-margin-bottom">Transaction Date: {{ transaction.createdAt }}</h5>

        <div>
            <Row>
                <Col span="10">
                    <img :src="transaction.asset.arweaveMetadata.metadata.image" width="300" />
                </Col>
                <Col span="12">
                    <p class="sm-margin-bottom">From: Startechup - {{ transaction.creatorWallet }}</p>
                    <p v-if="transaction.type === 'TRANSFERRED'">
                        Destination Wallet: {{ transaction.destinationWallet }}
                    </p>
                    <p v-else>Mint Address: {{ transaction.asset.mintAddress }}</p>
                </Col>
            </Row>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div v-if="transaction.type === 'TRANSFERRED'">
            <div
                class="emailTrans"
                v-if="transaction.congratulatoryEmail"
                v-html="transaction.congratulatoryEmail.textContent"
            ></div>
        </div>

        <Button class="pt-btn-1 nft-close-btn" @click="transaction.show = false">Close</Button>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        transaction: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            showModal: true,
        };
    },
    async created() {},
    methods: {},
};
</script>
