<template>
    <div class="timetracker-wrapper">
        <Card class="page-title" dis-hover :bordered="false"> ADMIN NFTS </Card>

        <Card dis-hover :bordered="false">
            <Row class="fullwidth">
                <Col span="3">
                    <p class="md-margin-right md-margin-bottom">
                        <strong>Filters</strong> <span><Icon type="md-list" size="20" /></span>
                    </p>

                    <p class="md-margin-bottom"><strong>Category</strong></p>
                    <div>
                        <CheckboxGroup
                            v-model="category"
                            @on-change="handleFilterChange($event, 'category')"
                            class="filters-grid"
                        >
                            <Checkbox v-for="cat in categories" :key="cat._id" :label="cat._id">
                                {{ categoryName(cat.name) }}</Checkbox
                            >
                        </CheckboxGroup>
                    </div>
                    <p class="md-margin-bottom"><strong>Status</strong></p>
                    <div class="filters-grid">
                        <CheckboxGroup
                            v-model="status"
                            @on-change="handleFilterChange($event, 'isMinted')"
                            class="filters-grid"
                        >
                            <Checkbox label="all">All</Checkbox>
                            <Checkbox label="true">Minted</Checkbox>
                            <Checkbox label="false">Not Minted</Checkbox>
                        </CheckboxGroup>
                    </div>
                    <p class="md-margin-bottom"><strong>Owner</strong></p>
                    <div>
                        <CheckboxGroup
                            v-model="owner"
                            @on-change="handleFilterChange($event, 'ownedByStu')"
                            class="filters-grid"
                        >
                            <Checkbox label="all">All</Checkbox>
                            <Checkbox label="true">Startechup</Checkbox>
                            <Checkbox label="false">Employee</Checkbox>
                        </CheckboxGroup>
                    </div>
                </Col>
                <Col span="21">
                    <ListAsset :key="filterChanged" :filters="{ ...filters }" />
                </Col>
            </Row>
        </Card>
    </div>
</template>

<script>
import ListAsset from '@/components/Nft/ListAsset';
import { getCategories } from '@/services/nft.services';
import { startCase } from 'lodash';

export default {
    components: {
        ListAsset,
    },
    data() {
        return {
            filterChanged: null,
            category: ['all'],
            status: ['all'],
            owner: ['all'],
            filters: {
                category: null,
                isMinted: null,
                ownedByStu: null,
            },
            categories: [],
        };
    },
    computed: {
        categoryName: () => (name) => {
            return startCase(name);
        },
    },
    async created() {
        const { data } = await getCategories();
        this.categories = [{ _id: 'all', name: 'All' }, ...data];
    },
    methods: {
        handleFilterChange(data, filterName) {
            this.filterChanged = Math.random();
            switch (filterName) {
                case 'isMinted':
                case 'ownedByStu':
                    if (
                        !data.length ||
                        data.find((id) => id === 'all') ||
                        (data.find((id) => id === 'true') && data.find((id) => id === 'false'))
                    ) {
                        this.filters[filterName] = null;
                        return;
                    }

                    this.filters[filterName] = data[0] === 'true' ? true : false;

                    break;

                default:
                    if (!data.length || data.find((id) => id === 'all')) {
                        this.filters.category = null;
                        return;
                    }

                    this.filters.category = this.category.join(',');
                    break;
            }
        },
    },
};
</script>
<style lang="scss"></style>
